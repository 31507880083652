module.exports=[
    {
        "patterns": [
            "http://(?:www\\.)?xkcd\\.com/\\d+/?"
        ],
        "name": "XKCD",
        "height": 110
    },
    {
        "patterns": [
            "https?://soundcloud.com/.*/.*"
        ],
        "name": "SoundCloud",
        "height": 140
    },
    {
        "patterns": [
            "https?://(?:www\\.)?flickr\\.com/.*",
            "https?://flic\\.kr/p/[a-zA-Z0-9]+"
        ],
        "name": "Flickr",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.ted\\.com/talks/.+\\.html"
        ],
        "name": "TED",
        "height": 110
    },
    {
        "patterns": [
            "http://(?:www\\.)?theverge\\.com/\\d{4}/\\d{1,2}/\\d{1,2}/\\d+/[^/]+/?$"
        ],
        "name": "The Verge",
        "height": 110
    },
    {
        "patterns": [
            "http://.*\\.viddler\\.com/.*"
        ],
        "name": "Viddler",
        "height": 110
    },
    {
        "patterns": [
            "https?://(?:www\\.)?avclub\\.com/article/[^/]+/?$"
        ],
        "name": "The AV Club",
        "height": 110
    },
    {
        "patterns": [
            "https?://(?:www\\.)?wired\\.com/([^/]+/)?\\d+/\\d+/[^/]+/?$"
        ],
        "name": "Wired",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.theonion\\.com/articles/[^/]+/?"
        ],
        "name": "The Onion",
        "height": 110
    },
    {
        "patterns": [
            "http://yfrog\\.com/[0-9a-zA-Z]+/?$"
        ],
        "name": "YFrog",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.duffelblog\\.com/\\d{4}/\\d{1,2}/[^/]+/?$"
        ],
        "name": "The Duffel Blog",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.clickhole\\.com/article/[^/]+/?"
        ],
        "name": "Clickhole",
        "height": 110
    },
    {
        "patterns": [
            "https?://(?:www.)?skitch.com/([^/]+)/[^/]+/.+",
            "http://skit.ch/[^/]+"
        ],
        "name": "Skitch",
        "height": 110
    },
    {
        "patterns": [
            "https?://(alpha|posts|photos)\\.app\\.net/.*"
        ],
        "name": "ADN",
        "height": 110
    },
    {
        "patterns": [
            "https?://gist\\.github\\.com/(?:[-0-9a-zA-Z]+/)?([0-9a-fA-f]+)"
        ],
        "name": "Gist",
        "height": 110
    },
    {
        "patterns": [
            "https?://www\\.(dropbox\\.com/s/.+\\.(?:jpg|png|gif))",
            "https?://db\\.tt/[a-zA-Z0-9]+"
        ],
        "name": "Dropbox",
        "height": 110
    },
    {
        "patterns": [
            "https?://[^\\.]+\\.wikipedia\\.org/wiki/(?!Talk:)[^#]+(?:#(.+))?"
        ],
        "name": "Wikipedia",
        "height": 110
    },
    {
        "patterns": [
            "http://www.traileraddict.com/trailer/[^/]+/trailer"
        ],
        "name": "TrailerAddict",
        "height": 110
    },
    {
        "patterns": [
            "http://lockerz\\.com/[sd]/\\d+"
        ],
        "name": "Lockerz",
        "height": 110
    },
    {
        "patterns": [
            "http://gifuk\\.com/s/[0-9a-f]{16}"
        ],
        "name": "GIFUK",
        "height": 110
    },
    {
        "patterns": [
            "http://trailers\\.apple\\.com/trailers/[^/]+/[^/]+"
        ],
        "name": "iTunes Movie Trailers",
        "height": 110
    },
    {
        "patterns": [
            "http://gfycat\\.com/([a-zA-Z]+)"
        ],
        "name": "Gfycat",
        "height": 110
    },
    {
        "patterns": [
            "http://bash\\.org/\\?(\\d+)"
        ],
        "name": "Bash.org",
        "height": 110
    },
    {
        "patterns": [
            "http://arstechnica\\.com/[^/]+/\\d+/\\d+/[^/]+/?$"
        ],
        "name": "Ars Technica",
        "height": 110
    },
    {
        "patterns": [
            "http://imgur\\.com/gallery/[0-9a-zA-Z]+"
        ],
        "name": "Imgur",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.asciiartfarts\\.com/[0-9]+\\.html"
        ],
        "name": "ASCII Art Farts",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.monoprice\\.com/products/product\\.asp\\?.*p_id=\\d+"
        ],
        "name": "Monoprice",
        "height": 110
    },
    {
        "patterns": [
            "http://boingboing\\.net/\\d{4}/\\d{2}/\\d{2}/[^/]+\\.html"
        ],
        "name": "Boing Boing",
        "height": 110
    },
    {
        "patterns": [
            "https?://github\\.com/([^/]+)/([^/]+)/commit/(.+)",
            "http://git\\.io/[_0-9a-zA-Z]+"
        ],
        "name": "Github Commit",
        "height": 110
    },
    {
        "patterns": [
            "https?://open\\.spotify\\.com/(track|album)/([0-9a-zA-Z]{22})"
        ],
        "name": "Spotify",
        "height": 110
    },
    {
        "patterns": [
            "https?://path\\.com/p/([0-9a-zA-Z]+)$"
        ],
        "name": "Path",
        "height": 110
    },
    {
        "patterns": [
            "http://www.funnyordie.com/videos/[^/]+/.+"
        ],
        "name": "Funny or Die",
        "height": 110
    },
    {
        "patterns": [
            "http://(?:www\\.)?twitpic\\.com/([^/]+)"
        ],
        "name": "Twitpic",
        "height": 110
    },
    {
        "patterns": [
            "https?://www\\.giantbomb\\.com/videos/[^/]+/\\d+-\\d+/?"
        ],
        "name": "GiantBomb",
        "height": 110
    },
    {
        "patterns": [
            "http://(?:www\\.)?beeradvocate\\.com/beer/profile/\\d+/\\d+"
        ],
        "name": "Beer Advocate",
        "height": 110
    },
    {
        "patterns": [
            "http://(?:www\\.)?imdb.com/title/(tt\\d+)"
        ],
        "name": "IMDB",
        "height": 110
    },
    {
        "patterns": [
            "http://cl\\.ly/(?:image/)?[0-9a-zA-Z]+/?$"
        ],
        "name": "CloudApp",
        "height": 110
    },
    {
        "patterns": [
            "http://clyp\\.it/.*"
        ],
        "name": "Clyp",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.hulu\\.com/watch/.*"
        ],
        "name": "Hulu",
        "height": 110
    },
    {
        "patterns": [
            "https?://(?:www|mobile\\.)?twitter\\.com/(?:#!/)?[^/]+/status(?:es)?/(\\d+)/?$",
            "https?://t\\.co/[a-zA-Z0-9]+"
        ],
        "name": "Twitter",
        "height": 110
    },
    {
        "patterns": [
            "https?://(?:www\\.)?vimeo\\.com/.+"
        ],
        "name": "Vimeo",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.amazon\\.com/(?:.+/)?[gd]p/(?:product/)?(?:tags-on-product/)?([a-zA-Z0-9]+)",
            "http://amzn\\.com/([^/]+)"
        ],
        "name": "Amazon",
        "height": 110
    },
    {
        "patterns": [
            "http://qik\\.com/video/.*"
        ],
        "name": "Qik",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.rdio\\.com/artist/[^/]+/album/[^/]+/?",
            "http://www\\.rdio\\.com/artist/[^/]+/album/[^/]+/track/[^/]+/?",
            "http://www\\.rdio\\.com/people/[^/]+/playlists/\\d+/[^/]+"
        ],
        "name": "Rdio",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.slideshare\\.net/.*/.*"
        ],
        "name": "SlideShare",
        "height": 110
    },
    {
        "patterns": [
            "http://imgur\\.com/([0-9a-zA-Z]+)$"
        ],
        "name": "Imgur",
        "height": 110
    },
    {
        "patterns": [
            "https?://instagr(?:\\.am|am\\.com)/p/.+"
        ],
        "name": "Instagram",
        "height": 110
    },
    {
        "patterns": [
            "http://www\\.twitlonger\\.com/show/[a-zA-Z0-9]+",
            "http://tl\\.gd/[^/]+"
        ],
        "name": "Twitlonger",
        "height": 110
    },
    {
        "patterns": [
            "https?://vine.co/v/[a-zA-Z0-9]+"
        ],
        "name": "Vine",
        "height": 490
    },
    {
        "patterns": [
            "http://www\\.urbandictionary\\.com/define\\.php\\?term=.+"
        ],
        "name": "Urban Dictionary",
        "height": 110
    },
    {
        "patterns": [
            "http://picplz\\.com/user/[^/]+/pic/[^/]+"
        ],
        "name": "Picplz",
        "height": 110
    },
    {
        "patterns": [
            "https?://(?:www\\.)?twitter\\.com/(?:#!/)?[^/]+/status(?:es)?/(\\d+)/photo/\\d+(?:/large|/)?$",
            "https?://pic\\.twitter\\.com/.+"
        ],
        "name": "Twitter",
        "height": 110
    }
]
